export const customColors = {
  signaturePurple: '#981867',
  signaturePurpleHalfTransparent: 'rgba(154, 24, 104, 0.82)',
  signaturePurpleTransparent: '#98186740',
  pastelRed: '#EF8F9A',
  pastelOrange: '#F8B392',
  pastelYellow: '#f7dda1',
  pastelGreen: '#A0CFA2',
  pastelBlue: '#71BEE7',
  pastelBlueTransparent: '#71BEE744',
  pastelPurple: '#8280B3',
}
