import { customColors } from 'constants/colors'
import { DESKTOP_STYLE, MOBILE_STYLE } from 'constants/variables'
import { useState } from 'react'
import { useIsMobile } from 'Scaffold'
import styled from 'styled-components'
import { GrClose } from 'react-icons/gr'
import { offers } from 'constants/methodsData'

export const Angebot = () => {
  const [currMethod, setCurrMethod] = useState<{ id: string; idx: number }>()

  const isMobile = useIsMobile()

  return (
    <Wrapper>
      {currMethod !== undefined && (
        <InfoOverlay>
          <CloseIconWrapper>
            <GrClose size={32} onClick={() => setCurrMethod(undefined)} />
          </CloseIconWrapper>
          {!isMobile ? (
            <div style={{ display: 'flex' }}>
              <img
                alt="behandlungsmethode"
                src={`methods/${currMethod.id}.jpg`}
                style={{
                  width: 480,
                  height: '100%',
                  maxHeight: 800,
                  objectFit: 'cover',
                  borderRadius: 16,
                }}
              />
              <div
                style={{
                  display: 'flex',
                  flex: 1,
                  height: 'calc(100vh - 400px)',
                  flexDirection: 'column',
                  padding: '0px 64px',
                  overflowY: 'auto',
                }}
              >
                <h1 style={{ marginBottom: 32 }}>
                  {offers[currMethod.idx].title}
                </h1>

                <p style={{ fontSize: 18 }}>{offers[currMethod.idx].text}</p>
              </div>
            </div>
          ) : (
            <>
              <h1 style={{ marginBottom: 32 }}>
                {offers[currMethod.idx].title}
              </h1>
              <p style={{ fontSize: 18 }}>{offers[currMethod.idx].text}</p>
            </>
          )}
        </InfoOverlay>
      )}
      <OffersWrapper>
        {offers.map((offer, idx) => {
          return (
            <MethodTile
              key={idx}
              onClick={() => {
                setCurrMethod({ id: offer.id, idx })
              }}
            >
              <Overlay>
                <h3
                  style={{
                    color: 'white',
                  }}
                >
                  {offer.title}
                </h3>
              </Overlay>
              <img
                alt="behandlungsmethode"
                src={`methods/${offer.id}.jpg`}
                style={{
                  position: 'absolute',
                  width: '100%',
                  height: '100%',
                  objectFit: 'cover',
                  objectPosition: 'center bottom',
                }}
              />
            </MethodTile>
          )
        })}
      </OffersWrapper>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  display: flex;
  flex: 1;
  height: 100%;
  position: relative;
  overflow-y: scroll;

  ${DESKTOP_STYLE}  {
    flex-direction: row;
  }

  ${MOBILE_STYLE} {
    flex-direction: column;
    padding: 40px;
  }
`

const OffersWrapper = styled.div`
  display: flex;
  flex: 1;
  height: 100%;
  flex-wrap: wrap;
  align-content: flex-start;

  ${DESKTOP_STYLE} {
    align-items: flex-start;
    justify-content: center;
  }

  ${MOBILE_STYLE} {
    justify-content: center;
  }
`

const Overlay = styled.div`
  display: flex;
  padding: 12px;
  align-items: flex-end;
  position: absolute;
  z-index: 2;
  opacity: 1;
  background: ${customColors.signaturePurpleHalfTransparent};

  ${DESKTOP_STYLE}  {
    width: 100%;
  }

  ${MOBILE_STYLE} {
    width: 100%;
  }

  transition-property: opacity;
  transition: 0.2s ease-in;
`

const MethodTile = styled.div`
  display: flex;
  align-items: flex-end;
  border-radius: 8px;
  margin: 16px;
  background-color: beige;
  overflow: hidden;
  cursor: pointer;
  position: relative;

  transition-property: transform;
  transition: 0.2s ease-in;

  ${DESKTOP_STYLE}  {
    width: 280px;
    height: 360px;

    :hover {
      transform: scale(1.05);
    }
  }

  ${MOBILE_STYLE} {
    width: 100%;
    max-width: 280px;
    height: 400px;
  }

  /* :hover ${Overlay} {
    opacity: 0;
  } */
`

const InfoOverlay = styled.div`
  position: fixed;
  background-color: white;
  z-index: 200;
  display: flex;
  flex-direction: column;
  padding: 24px;

  ${DESKTOP_STYLE}  {
    padding: 120px 64px 64px 64px;
    width: 100vw;
    height: 100vh;
    left: 0;
    top: 0;
    filter: drop-shadow(0px 0px 20px #eaeaea);
  }

  ${MOBILE_STYLE} {
    padding: 32px 48px 104px 32px;
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    overflow-y: scroll;
  }
`

const CloseIconWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  cursor: pointer;
  position: fixed;

  ${DESKTOP_STYLE}  {
    top: 80px;
    right: 80px;
  }

  ${MOBILE_STYLE} {
    top: 48px;
    right: 16px;
  }
`
