import { Artwork3Component } from 'components/Artwork3'
import { ContactComponent } from 'components/ContactComponent'
import { ContactForm } from 'components/ContactForm'
import { DESKTOP_STYLE, MOBILE_STYLE } from 'constants/variables'
import { useState } from 'react'
import { GrClose } from 'react-icons/gr'
import styled from 'styled-components'

export const Home = () => {
  // const [currentQuote, setCurrentQuote] = useState<string>()
  const [displayContactForm, setDisplayContactForm] = useState(false)

  return (
    <Wrapper>
      {displayContactForm && (
        <InfoOverlay>
          <CloseIconWrapper>
            <GrClose size={32} onClick={() => setDisplayContactForm(false)} />
          </CloseIconWrapper>
          <img
            style={{ width: 320, marginBottom: 32 }}
            alt="Logo Physio ZueriWest"
            src="/logo.png"
          />
          <ContactForm />
        </InfoOverlay>
      )}
      <SiteSplitter>
        <ArtWrapper>
          <Artwork3Component />
        </ArtWrapper>
      </SiteSplitter>
      <SiteSplitter>
        <ContactComponentWrapper>
          <ContactComponent setDisplayContactForm={setDisplayContactForm} />
        </ContactComponentWrapper>
      </SiteSplitter>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  display: flex;
  flex: 1;
  height: 100%;
  overflow-x: hidden;
  overflow-y: scroll;

  ${DESKTOP_STYLE}  {
    flex-direction: row;
    padding: 0px 80px 100px 80px;
  }

  ${MOBILE_STYLE} {
    flex-direction: column;
    /* padding: 104px 0px; */
  }
`

const ArtWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  ${DESKTOP_STYLE} {
    padding-bottom: 160px;
  }

  /* ${MOBILE_STYLE} {
    display: none;
  } */
`

const ContactComponentWrapper = styled.div`
  display: flex;

  ${DESKTOP_STYLE}  {
    padding-bottom: 140px;
  }

  ${MOBILE_STYLE} {
  }
`

// const QuoteWrapper = styled.div`
//   display: flex;
//   flex: 0 0 100px;
//   justify-content: center;
//   align-items: center;
//   max-width: 640px;
//   margin-top: 40px;
// `

const SiteSplitter = styled.div`
  display: flex;
  flex: 1;
  height: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

// const Quote = styled.h3`
//   opacity: 0;
//   animation: ${quoteAppear} 2s linear;
//   animation-fill-mode: forwards;
//   text-align: center;
//   color: grey;
//   font-style: italic;
// `

const InfoOverlay = styled.div`
  position: fixed;
  background-color: white;
  z-index: 200;
  display: flex;
  flex-direction: column;
  padding: 24px;
  align-items: center;

  ${DESKTOP_STYLE}  {
    padding: 120px 160px;
    width: 100vw;
    height: calc(100vh - 120px);
    top: 160px;
    left: 0;
    filter: drop-shadow(0px 0px 20px #eaeaea);
  }

  ${MOBILE_STYLE} {
    padding: 104px 48px 104px 32px;
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    overflow-y: scroll;
  }
`

const CloseIconWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  cursor: pointer;
  position: fixed;

  ${DESKTOP_STYLE}  {
    top: 80px;
    right: 80px;
  }

  ${MOBILE_STYLE} {
    top: 48px;
    right: 16px;
  }
`
