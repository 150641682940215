import { customColors } from 'constants/colors'
import styled from 'styled-components'
import {
  MdCall,
  MdLocationOn,
  MdMail,
  MdTrain,
  MdOutlineCheck,
  MdCalendarToday,
} from 'react-icons/md'
import { AiOutlineForm } from 'react-icons/ai'
import { useState } from 'react'
import { DESKTOP_STYLE, MOBILE_STYLE, sleep } from 'constants/variables'
import { useIsMobile } from 'Scaffold'

const phoneNr = '044 554 90 66'
const email = 'info@physiozueriwest.ch'
const address = 'Hohlstrasse 488, 8048 Zürich'

export const ContactComponent = (props: {
  setDisplayContactForm: (v: boolean) => void
}) => {
  const [copied, setCopied] = useState(false)

  const isMobile = useIsMobile()

  const handleClick = async () => {
    navigator.clipboard.writeText(`${phoneNr}`)
    setCopied(true)
    await sleep(3000)
    setCopied(false)
  }
  return (
    <ContactWrapper>
      <ContactRow>
        <IconWrapper>
          <MdCall size={40} color={customColors.pastelGreen} />
        </IconWrapper>
        <ContactText
          style={{ cursor: 'pointer' }}
          onClick={() => handleClick()}
        >
          <p
            style={{
              marginRight: 32,
              fontFamily: 'Quicksand',
              fontSize: isMobile ? 16 : 18,
            }}
          >
            {phoneNr}
          </p>
          {copied ? (
            <>
              <p
                style={{
                  color: customColors.pastelPurple,
                  fontSize: 14,
                  marginRight: 8,
                  textDecoration: 'unset',
                }}
              >
                kopiert
              </p>
              <MdOutlineCheck color={customColors.pastelPurple} size={14} />
            </>
          ) : (
            <></>
          )}{' '}
        </ContactText>
      </ContactRow>
      <ContactRow>
        <IconWrapper>
          <MdMail size={40} color={customColors.pastelBlue} />
        </IconWrapper>
        <ContactText>
          <a style={{ fontFamily: 'Quicksand' }} href={`mailto:${email}`}>
            {email}
          </a>
        </ContactText>
      </ContactRow>
      <ContactRow>
        <IconWrapper>
          <MdCalendarToday size={40} color={customColors.pastelOrange} />
        </IconWrapper>
        <ContactText>
          <a
            href="https://onlinecalendar.medidoc.ch/BookAppointment?cgid=t82xtm7Tk0q_l0gpU7zyCA&ssid=T3jR4DKfj0yrLDvXEacIRg"
            target="_blank"
            rel="noreferrer"
            style={{ fontFamily: 'Quicksand' }}
          >
            Online Terminvereinbarung
          </a>
        </ContactText>
      </ContactRow>
      <ContactRow>
        <IconWrapper onClick={() => {}}>
          <AiOutlineForm size={40} color={customColors.pastelPurple} />
        </IconWrapper>
        <ContactText
          style={{ cursor: 'pointer' }}
          onClick={() => props.setDisplayContactForm(true)}
        >
          Kontaktformular
        </ContactText>
      </ContactRow>
      <ContactRow>
        <IconWrapper onClick={() => {}}>
          <MdLocationOn size={40} color={customColors.pastelRed} />
        </IconWrapper>
        <ContactText>
          <a
            href="https://goo.gl/maps/DcKD1herPjufeUU8A"
            target="_blank"
            rel="noreferrer"
            style={{ fontFamily: 'Quicksand' }}
          >
            {address}
          </a>
        </ContactText>
      </ContactRow>
      <ContactRow>
        <IconWrapper onClick={() => {}}>
          <MdTrain size={40} color={customColors.pastelPurple} />
        </IconWrapper>
        <ContactText>
          <a
            href="https://goo.gl/maps/qJqrbLDg9rZLh8sN8"
            target="_blank"
            rel="noreferrer"
            style={{ fontFamily: 'Quicksand' }}
          >
            Bahnhof Altstetten 7' zu Fuss
          </a>
        </ContactText>
      </ContactRow>
    </ContactWrapper>
  )
}

const ContactWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;

  ${DESKTOP_STYLE}  {
    flex: 0 0 400px;
    width: 400px;
    top: 80px;
    right: 80px;
  }

  ${MOBILE_STYLE} {
    width: 100%;
  }
`

const IconWrapper = styled.div`
  width: 32px;
  height: 32px;
  transition-property: transform;
  transition: 0.3s ease-in-out;
`

const ContactRow = styled.div`
  display: flex;
  flex: 0 0 64px;
  align-items: center;
  padding: 0px 16px;
  width: 100%;
  margin-bottom: 16px;
`

const ContactText = styled.h3`
  display: flex;
  align-items: center;
  margin-left: 32px;

  ${DESKTOP_STYLE}  {
  }

  ${MOBILE_STYLE} {
    font-size: 16px;
  }

  :hover {
    text-decoration: underline;
  }
`
