import { customColors } from 'constants/colors'
import { DESKTOP_STYLE, MOBILE_STYLE } from 'constants/variables'
import { useState } from 'react'
import { GrClose } from 'react-icons/gr'
import styled from 'styled-components'

export const Galerie = () => {
  const [currMethod, setCurrMethod] = useState<number>()

  return (
    <Wrapper>
      {currMethod !== undefined && (
        <InfoOverlay>
          <CloseIconWrapper>
            <GrClose size={32} onClick={() => setCurrMethod(undefined)} />
          </CloseIconWrapper>
          <div
            style={{
              display: 'flex',
              height: '100%',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <DetailImg
              alt="behandlungsmethode"
              src={`gallery/${currMethod}.jpg`}
            />
          </div>
        </InfoOverlay>
      )}
      <OffersWrapper>
        {[...Array(14)].map((_, idx) => {
          return (
            <MethodTile
              key={idx}
              onClick={() => {
                setCurrMethod(idx + 1)
              }}
            >
              <img
                alt="behandlungsmethode"
                src={`gallery/${idx + 1}.jpg`}
                style={{
                  position: 'absolute',
                  width: '100%',
                  height: '100%',
                  objectFit: 'cover',
                  objectPosition: 'center bottom',
                }}
              />
            </MethodTile>
          )
        })}
      </OffersWrapper>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  display: flex;
  flex: 1;
  position: relative;

  ${DESKTOP_STYLE}  {
    flex-direction: row;
  }

  ${MOBILE_STYLE} {
    flex-direction: column;
    padding: 40px;
  }
`

const OffersWrapper = styled.div`
  display: flex;
  flex: 1;
  height: 100%;
  flex-wrap: wrap;
  align-content: flex-start;
  overflow-y: hidden;

  ${DESKTOP_STYLE} {
    align-items: flex-start;
    justify-content: center;
  }

  ${MOBILE_STYLE} {
    justify-content: center;
  }
`

const Overlay = styled.div`
  display: flex;
  padding: 12px;
  align-items: flex-end;
  position: absolute;
  z-index: 2;
  opacity: 1;
  background: ${customColors.signaturePurpleHalfTransparent};

  ${DESKTOP_STYLE}  {
    width: 100%;
  }

  ${MOBILE_STYLE} {
    width: 100%;
  }

  transition-property: opacity;
  transition: 0.2s ease-in;
`

const MethodTile = styled.div`
  display: flex;
  align-items: flex-end;
  border-radius: 8px;
  margin: 16px;
  background-color: beige;
  overflow: hidden;
  cursor: pointer;
  position: relative;

  transition-property: transform;
  transition: 0.2s ease-in;

  ${DESKTOP_STYLE}  {
    width: 360px;
    height: 280px;

    :hover {
      transform: scale(1.05);
    }
  }

  ${MOBILE_STYLE} {
    width: 100%;
    max-width: 360px;
    height: 240px;
  }

  /* :hover ${Overlay} {
    opacity: 0;
  } */
`

const InfoOverlay = styled.div`
  position: fixed;
  background-color: white;
  z-index: 200;
  display: flex;
  flex-direction: column;
  padding: 24px;

  ${DESKTOP_STYLE}  {
    padding: 80px;
    width: 100vw;
    height: 100vh;
    left: 0;
    top: 0;
    filter: drop-shadow(0px 0px 20px #eaeaea);
  }

  ${MOBILE_STYLE} {
    padding: 24px;
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    overflow-y: scroll;
  }
`

const CloseIconWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  cursor: pointer;
  position: fixed;

  ${DESKTOP_STYLE}  {
    top: 80px;
    right: 80px;
  }

  ${MOBILE_STYLE} {
    top: 48px;
    right: 16px;
  }
`

const DetailImg = styled.img`
  width: 100%;
  object-fit: cover;
  border-radius: 16px;

  ${DESKTOP_STYLE}  {
    height: 100%;
    max-width: 1300px;
    max-height: 800px;
  }

  ${MOBILE_STYLE} {
    max-width: 1300px;
    max-height: 320px;
  }
`
