import { customColors } from 'constants/colors'
import styled from 'styled-components'

export const ContactForm = () => {
  return (
    <FormWrapper>
      <Form name="contact" method="POST" data-netlify="true">
        <input
          type="hidden"
          name="subject"
          value="Kontaktanfrage von Homepage"
        />
        <input type="hidden" name="form-name" value="contact" />
        <Text>
          <Label>
            Name <Input type="text" name="name" />
          </Label>
        </Text>
        <Text>
          <Label>
            Email <Input type="email" name="email" />
          </Label>
        </Text>
        <Text style={{ flex: '0 0 120px' }}>
          <Label style={{ height: 120 }}>
            Nachricht <TextArea name="message"></TextArea>
          </Label>
        </Text>
        <Text>
          <Button type="submit">Abschicken</Button>
        </Text>
      </Form>
    </FormWrapper>
  )
}

const Button = styled.button`
  all: unset;
  display: flex;
  flex: 1;
  height: 48px;
  margin-top: 16px;
  border-radius: 8px;
  justify-content: center;
  align-items: center;
  background-color: ${customColors.signaturePurpleTransparent};
  font-weight: bold;
  cursor: pointer;
  :hover {
    background-color: ${customColors.signaturePurpleHalfTransparent};
    color: white;
  }
  :disabled {
    background-color: grey;
  }
  transition-property: all;
  transition-duration: 0.5s;
`

const FormWrapper = styled.div`
  display: flex;
  flex: 0 0 400px;
  width: 100%;
  max-width: 400px;
`
const Input = styled.input`
  flex: 0 0 32px;
  width: 100%;
  border-radius: 4px;
  border: 2px solid ${customColors.signaturePurpleTransparent};
  margin: 8px 0px;
`
const Form = styled.form`
  display: flex;
  flex: 1;
  height: 100%;
  flex-direction: column;
`

const Label = styled.label`
  display: flex;
  flex-direction: column;
  flex: 1;
  height: 80px;
`

const TextArea = styled.textarea`
  display: flex;
  flex: 1;
  height: 120px;
  resize: none;
  border-radius: 4px;
  border: 2px solid ${customColors.signaturePurpleTransparent};
  margin: 8px 0px;
`

const Text = styled.p`
  display: flex;
  width: 100%;
`
