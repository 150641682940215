import styled from 'styled-components'

import { TeamMemberComponent } from 'components/TeamMemberComponent'
import { DESKTOP_STYLE, MOBILE_STYLE } from 'constants/variables'
import { team } from 'constants/teamData'

export const UeberUns = () => {
  return (
    <Wrapper>
      {team.map((member, idx) => {
        return <TeamMemberComponent member={member} />
      })}
    </Wrapper>
  )
}

const Wrapper = styled.div`
  display: flex;
  flex: 1;
  flex-wrap: wrap;
  align-content: flex-start;
  justify-content: center;
  overflow-x: hidden;

  ${DESKTOP_STYLE}  {
    padding: 0px 104px;
  }

  ${MOBILE_STYLE} {
    padding: 32px 0px;
    justify-content: center;
  }
`
